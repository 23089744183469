import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Game } from '@/types/pbskids-graph';
import ClickAnimation from '@/components/base/ClickAnimationWrapper';
import { linkByFeatureFlag } from '@/components/modules/GameCard';
import { handleLinkClickWithDelay } from '@/utils/link-with-delay';
import styles from './Randomizer.module.scss';

interface Props {
  altText?: string,
  classes?: string,
  games?: Game[],
  sizes?: string,
  staticImage: string,
}

const pickRandomGame = (games: Game[] | undefined) => {
  if (!games) return;

  const randomIndex = Math.floor(Math.random() * games.length);
  const randomGame = games[randomIndex];

  return randomGame;
};

const Randomizer: React.FC<Props> = ({
  altText,
  classes,
  games,
  sizes,
  staticImage,
}) => {
  const randomGame = pickRandomGame(games);

  // TODO: Update this conditional when Game Player Feature Flag is removed
  const gameLink = randomGame ? linkByFeatureFlag(randomGame) : '';

  return ( gameLink &&
    <article className={`${styles.randomizer} ${classes}`}>
      <Link
        className={ styles.randomizerLink }
        href={ gameLink }
        onClick={(event) => handleLinkClickWithDelay(event, 500) }
      >
        <ClickAnimation
          animationName='jellySquishClick'
        >
          <Image
            className={ styles.randomizerImage }
            src={staticImage}
            alt={ altText || 'Randomizer'}
            width={ 300 }
            height={ 400 }
            objectFit='cover'
            sizes={ sizes }
          />
        </ClickAnimation>
      </Link>
    </article>
  );
};

export default Randomizer;
